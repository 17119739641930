.div-error {
    background-color: #f8d7da;
    color: #721c24;
    margin-top: 1rem;
    border: none;
    border-radius: 0.1rem;
    padding: 1.2rem 0.8rem;
}

.close-error {
    float: right;
    cursor: pointer;
}